<template>
    <v-container fluid >
        <Lista :getParams="this.params"></Lista>
    </v-container>
</template>

<script>
const Lista = () => import("./components/Lista.vue");
export default {
    name: "MisCursosHistorial",
    data() {
        return {
            loading: true,
            params: {
                aprobado: "TODOS",
            }
        }
    },
    components: {
        Lista
    },
    methods: {

    },
    created() {

    }
}
</script>

<style>

</style>
